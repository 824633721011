.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000;
  font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  padding: 1rem;
}

.animated-titles {
  position: relative;
  height: 100px; /* Set a fixed height for the animated titles */
  margin-bottom: 2rem;
  z-index: 1; /* Make sure the titles appear above other content */
  color: white ;
}

.title-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  opacity: 0;
  animation: cycleTitles 9s linear infinite;
}


/* Adjust animation delays as per the JavaScript setup */
.title-animation.title-0 { animation-delay: 0s; }
.title-animation.title-1 { animation-delay: 3s; }
.title-animation.title-2 { animation-delay: 6s; }

@keyframes cycleTitles {
  0%, 5%, 30%, 100% { opacity: 0; }
  10%, 25% { opacity: 1; } /* Visible for longer */
}

.login-button, .signup-button, .anonymous-button {
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s, opacity 0.2s;
  margin-top: 10px; /* Add space between buttons */
  width: 80%; /* Set a max-width for the buttons on mobile */
  max-width: 300px; /* Max button width */
}

.login-button, .signup-button {
  background-color: #ff4081; /* Pink color for primary actions */
  color: white;
}

.anonymous-button {
  background-color: darkblue; /* Dark blue color for secondary actions */
  color: white;
}

/* Hover effects */
.login-button:hover, .signup-button:hover, .anonymous-button:hover {
  opacity: 0.8;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .login-button, .signup-button, .anonymous-button {
    padding: 0.8rem; /* Smaller padding for buttons */
    font-size: 0.9rem; /* Smaller font size for text inside buttons */
  }

  /* Adjust the size and line-height of the animated titles for smaller screens */
  .title-animation {
    font-size: 1rem;
    line-height: 1.2;
  }
}

.online-users-count {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 10px;
  color: black;
  font-size: 1rem;
}