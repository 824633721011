.dashboard-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.dashboard-container {
  width: 80%;
  max-width: 600px;
  margin-top: -100px; /* Adjust as needed to center the container */
}

.link-button {
  display: inline-block; /* Adjust this if you want the buttons to be in a column */
  width: 150px; /* Example fixed width; adjust as necessary */
  margin: 10px 0; /* Spacing between buttons */
  padding: 10px 0; /* Padding for top and bottom */
  background: #ff4081; /* Button color */
  color: white;
  text-align: center; /* Center the text inside the button */
  text-decoration: none;
  border: none;
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: background-color 0.3s;
}

.link-button:hover {
  background-color: darkblue;
}


h1 {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 300;
  animation: fadeIn 2s both;
}

.footer-text {
  position: bottom;
  /* bottom: 0; */
  width: 100%;
  text-align: center;
  padding: 10px;
  background-color: #000; /* Or whatever background color fits the theme of your page */
  color: #fff; /* Adjust text color as needed */
}


@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}
/* Responsive design adjustments */
@media (max-width: 768px) {
  .dashboard-container {
    width: 90%; /* Full width on smaller screens */
    padding: 20px;
  }
}
