.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signup-form {
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 300px; /* Adjust width as necessary */
  }
  
  .signup-form h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .signup-form input,
  .signup-form select,
  .signup-form textarea {
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .signup-form textarea {
    height: 100px; /* Adjust height as necessary */
    resize: vertical; /* Allow vertical resize only */
  }
  
  .signup-form button {
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.2s;
  }
  
  .signup-form button:hover {
    background-color: #0056b3;
  }
  
  .verification-waiting-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .verification-waiting-container h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .verification-waiting-container p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .verification-waiting-container button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    background-color: #28a745;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .verification-waiting-container button:hover {
    background-color: #1e7a34;
  }
  