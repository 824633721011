.login-with-email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2; /* Light grey background */
  }
  
  .login-with-email-form {
    padding: 2rem;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px; /* Control the form width */
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .login-with-email-form h1 {
    color: #333;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .login-with-email-form label {
    font-weight: bold;
    margin-bottom: .5rem;
  }
  
  .login-with-email-form input {
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 1rem; /* Space between input fields */
  }
  
  .submit-button, .reset-password-button {
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: darkblue; /* Bootstrap blue */
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Darken button on hover */
  }
  
  .reset-password-button {
    background-color: #ff4081; /* Bootstrap red */
    margin-top: 0.5rem; /* Space from the submit button */
  }
  
  .reset-password-button:hover {
    background-color: #bd2130; /* Darken button on hover */
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .login-with-email-form {
      width: 90%;
      margin: 0 5%;
      padding: 1rem;
    }
  }
  